jQuery(function($){

    if ($('body.blog').length > 0)
    {
        /**************************
     * Listar Posts
     *****************/
    var page = 1;
    var slug = $('.list-group-item.active').data('slug');
    var listarPostsAjax = function (page, slug) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarPosts',
                page: page,
                slug: slug
            },
            beforeSend:function () {
               console.log('carregando posts');
                $('.loader').addClass('loading');
                $('.progress').removeClass('d-none');
                $("main").toggleClass('block-scroll'); 
                //$('.bottom-floaters-container').toggleClass('opacity-none');
            }
        })
        .done(function(response){
            console.log('posts carregados');
            //$("main").toggleClass('block-scroll'); 
            $('.loader').removeClass('loading');
            $('.progress').addClass('d-none');
            setTimeout(function() {
                //alert("Hello");
                $('#listar-posts').removeClass('ad-fixed-height');
               }, 1000);
            $('#listar-posts').html(response);
            //$('.bottom-floaters-container').toggleClass('opacity-none');
        })
        .fail(function(){
            console.log('opss erro a carregar posts!!!');
        });
    };
    listarPostsAjax(page, slug);

    //Ação do botão da categoria
    $('.list-group-item').on('click', function () {
       // alert ("teste");
       slug = $(this).data('slug');
       listarPostsAjax(1, slug);
       $('.list-group-item').removeClass('active');
       $(this).addClass('active');
    });


    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
       listarPostsAjax(page, slug);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });


   
    /******************************************************************************** */

    }

    

    

});